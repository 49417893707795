import { EMPTY_LINE, createBigText } from './entries'

const months = ['sty', 'lut', 'mar', 'kwi', 'maj', 'cze', 'lip', 'sie', 'wrz', 'paz', 'lis', 'gru']
const today = new Date()
const date = today.getDate()
const month = months[today.getMonth()]
const year = today.getFullYear()

const formattedDate = `${date} ${month} ${year}`

export const createDateLabel = () => [
  EMPTY_LINE,
  EMPTY_LINE,
  createBigText(formattedDate, 1, 1),
  EMPTY_LINE,
  EMPTY_LINE,
]
