import { Paper } from '@material-ui/core'
import { useHistory } from 'react-router-dom'
import { Textfit } from 'react-textfit'
import styles from './TileButton.module.css'

const buildLabelURL = (entriesArray) => {
  const entriesObject = entriesArray.reduce(
    (accumulator, current, index) => ({
      ...accumulator,
      [`${index}`]: current,
    }),
    {}
  )
  return encodeURI(
    `my.bluetoothprint.scheme://https://kitchen-printer.apps.devways.pl/api/json.php?object=${JSON.stringify(
      entriesObject
    )}`
  )
}

export const TileButton = ({ children, labelContent, link }) => {
  const history = useHistory()
  const getGoToPrintURL = (url) => () => (window.location.href = url)

  const getGoToRouterLink = (link) => () => {
    history.push(link)
  }

  let onClickFunc
  if (labelContent) {
    onClickFunc = getGoToPrintURL(buildLabelURL(labelContent))
  } else if (link) {
    onClickFunc = getGoToRouterLink(link)
  }

  return (
    <Paper onClick={onClickFunc} component="span" classes={{ root: styles.container }}>
      <div className={styles.labelContent}>
        <Textfit className={styles.textFitContainer}>{children}</Textfit>
      </div>
    </Paper>
  )
}
