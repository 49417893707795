export const EMPTY_LINE = {
  type: 0,
  content: ' ',
  align: 0,
  bold: 0,
}

export const createDivider = (sign = '-') => ({
  type: 0,
  content: new Array(26).fill(sign).join(''),
  align: 1,
  bold: 1,
})

export const createText = (content, align = 0, bold = 0) => ({
  type: 0,
  format: 0,
  content,
  bold,
  align,
})

export const createHighText = (content, align = 0, bold = 0) => ({
  type: 0,
  format: 1,
  content,
  bold,
  align,
})

export const createWideText = (content, align = 0, bold = 0) => ({
  type: 0,
  format: 3,
  content,
  bold,
  align,
})

export const createBigText = (content, align = 0, bold = 0) => ({
  type: 0,
  format: 2,
  content,
  bold,
  align,
})

export const createImage = (path, align = 1) => ({
  type: 1,
  path,
  align,
})

export const createQRCode = (value, size = 40, align = 1) => ({
  type: 3,
  value,
  size,
  align,
})
