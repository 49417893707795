import { EMPTY_LINE, createImage } from './entries'

export const createPemosCoffeeLabel = () => [
  createImage('https://kitchen-printer.apps.devways.pl/images/kawa-pemo.jpg'),
  EMPTY_LINE,
]

export const createFragileLabel = () => [
  createImage('https://kitchen-printer.apps.devways.pl/images/nie-rzucac.jpg'),
  EMPTY_LINE,
  EMPTY_LINE,
]
